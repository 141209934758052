/* eslint-disable import/no-cycle */
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {
  GetCategoryProductsQuery,
  GetCategoryProductsQueryVariables,
  GetDataQuery,
  GetFilteredProductsQuery,
  GetFilteredProductsQueryVariables,
  ProductFilters,
  GetRelatedItemsQuery,
} from '../graphql/queries-schema';
import {Topology} from '@wix/wixstores-client-core/dist/es/src/constants';
import {ICollectionIdsFilterDTO, IGetInitialData, IOldGetInitialData, ISorting} from '../types/galleryTypes';
import {query as oldGetDataQueryString} from '../graphql/oldGetData.graphql';
import {query as getDataQueryString} from '../graphql/getData.graphql';
import {query as getCategoryProductsQueryString} from '../graphql/getCategoryProducts.graphql';
import {query as getFilteredProductsQueryString} from '../graphql/getFilteredProducts.graphql';
import {query as GetRelatedItemsQueryString} from '../graphql/getRelatedItems.graphql';
import _ from 'lodash';
import {ProductSortField, SortDirection} from '@wix/wixstores-graphql-schema';
import {MAX_PRODUCTS} from '../constants';

export class DataApi {
  constructor(private readonly siteStore: SiteStore) {
    //
  }

  public oldGetInitialData({
    externalId,
    compId,
    limit,
  }: IOldGetInitialData): Promise<{
    data: GetDataQuery;
  }> {
    const data: any = {
      query: oldGetDataQueryString,
      source: 'WixStoresWebClient',
      operationName: 'oldGetData',
      variables: {externalId: externalId || '', compId, limit},
    };

    return this.sendRequest(data) as Promise<{
      data: GetDataQuery;
    }>;
  }

  public getInitialData({
    externalId,
    compId,
    limit,
    sort,
    filters,
    offset,
    withOptions,
  }: IGetInitialData): Promise<{
    data: GetDataQuery;
  }> {
    const maxLimit = Math.min(limit, MAX_PRODUCTS);
    const data: any = {
      query: getDataQueryString,
      source: 'WixStoresWebClient',
      operationName: 'getData',
      variables: {
        externalId: externalId || '',
        compId,
        limit: maxLimit,
        sort,
        filters,
        offset,
        withOptions,
      },
    };

    return this.sendRequest(data) as Promise<{
      data: GetDataQuery;
    }>;
  }

  public getRelatedItems({
    externalId = '',
    productIds,
  }: {
    externalId: string;
    productIds: string[];
  }): Promise<{data: GetRelatedItemsQuery}> {
    const data: any = {
      query: GetRelatedItemsQueryString,
      source: 'WixStoresWebClient',
      operationName: 'getRelatedItems',
      variables: {externalId, productIds},
    };

    return this.sendRequest(data) as Promise<{data: GetRelatedItemsQuery}>;
  }

  private sendRequest(data: any): Promise<any> {
    let getFunc = this.siteStore.httpClient.post.bind(this.siteStore.httpClient);
    if (this.siteStore.experiments.enabled('specs.stores.GraphqlGet')) {
      getFunc = this.siteStore.tryGetGqlAndFallbackToPost.bind(this.siteStore);
    }
    return getFunc(this.siteStore.resolveAbsoluteUrl(`/${Topology.STOREFRONT_GRAPHQL_URL}`), data);
  }

  public async getCategoryProducts(
    variables: GetCategoryProductsQueryVariables
  ): Promise<{data: GetCategoryProductsQuery}> {
    const data: any = {
      query: getCategoryProductsQueryString,
      source: 'WixStoresWebClient',
      operationName: 'getCategoryProducts',
      variables,
    };

    return this.sendRequest(data);
  }

  public async getProducts(
    fromIndex: number,
    toIndex: number,
    filters: ProductFilters | null,
    collectionIds: ICollectionIdsFilterDTO,
    withOptions: boolean,
    sorting?: ISorting
  ): Promise<any> {
    const offset = fromIndex;
    const limit = toIndex - fromIndex;
    const {
      data: {
        catalog: {
          category: {
            productsWithMetaData: {list, totalCount},
          },
        },
      },
    } = await this.getProductsByGraphQL(collectionIds.mainCategory, offset, limit, filters, withOptions, sorting);
    return {list: _.compact(list), totalCount};
  }

  public async getProductsByGraphQL(
    mainCollectionId: string,
    offset: number,
    limit: number,
    filters: ProductFilters | null,
    withOptions: boolean,
    sorting?: ISorting
  ): Promise<{data: GetFilteredProductsQuery}> {
    const sort = sorting
      ? {
          direction: sorting.direction === 'ASC' ? SortDirection.Ascending : SortDirection.Descending,
          sortField: sorting.field as ProductSortField,
        }
      : null;

    const variables: GetFilteredProductsQueryVariables = {
      mainCollectionId,
      offset,
      limit,
      sort,
      filters,
      withOptions,
    };

    const data = {
      variables,
      query: getFilteredProductsQueryString,
      source: 'WixStoresWebClient',
      operationName: 'getFilteredProducts',
    };

    return this.sendRequest(data);
  }
}
